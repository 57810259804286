@font-face {
  font-family: "Piazzolla";
  src: local("Piazolla"), url(./assets//fonts/Piazzolla.ttf) format("ttf");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spinner {
  position: absolute;
}

@media (min-width: 650px) {
  .spinner {
    position: absolute;
    top: 410px;
    left: 655px;
    z-index: 9999;
  }
}
@media (min-width: 900px) {
  .spinner {
    position: absolute;
    left: 470px;
    z-index: 9999;
  }
}
@media (max-width: 855px) {
  .spinner {
    position: absolute;
    left: 420px;
    z-index: 9999;
  }
}

@media (max-width: 700px) {
  .spinner {
    position: absolute;
    left: 200px;
    z-index: 9999;
  }
}
@media (max-width: 800px) {
  .spinner {
    position: absolute;
    top: 420px;
    z-index: 999;
  }
}
@media (min-width: 1000px) {
  .spinner {
    position: absolute;
    top: 400px;
    left: 680px;
    z-index: 999;
  }
}
